import { IHttpClient, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  createNewPortfolioApp,
  queryProjectsWithCollectionInfo,
  queryProjects,
  getProjectPageData,
} from '@wix/ambassador-portfolio-projects-v1-project/http';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import { SortOrder } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import {
  getCollectionSlugFromUrl,
  getProjectSlugFromUrl,
} from '../utils/urlsUtils';
import { listProjectItems } from '@wix/ambassador-portfolio-project-items-v1-project-item/http';
import {
  Item,
  PagingMetadataV2,
} from '@wix/ambassador-portfolio-project-items-v1-project-item/types';
import { getPortfolioSettings as getPortfolioSettingsReq } from '@wix/ambassador-portfolio-portfolio-app-v1-portfolio-settings/http';

export const PROJECT_ITEM_PAGING_LIMIT = 50;

export const getPortfolioApi = (httpClient: IHttpClient) => {
  const notifyPortfolioAppCreation = async () => {
    try {
      const data = await httpClient.request(createNewPortfolioApp({}));
      return data;
    } catch (e) {}
  };
  return { notifyPortfolioAppCreation };
};

export const getCollections = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: false },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections;
    });
};
export const getCollectionsWithHidden = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections;
    });
};
export const getAllCollectionProjectsWithInfo = async (
  httpClient: IHttpClient,
  collectionId: string,
) => {
  const collectionProjects: Item[] = [];
  let hasNext = false;
  let cursor: string | null = null;
  let filter: Record<string, any> | null = {
    collectionId: { $eq: collectionId },
    hidden: { $eq: false },
  };
  let sort: any = [{ fieldName: 'sortOrder', order: SortOrder.ASC }];
  do {
    try {
      const res = await httpClient.request(
        queryProjectsWithCollectionInfo({
          query: {
            filter,
            sort,
            cursorPaging: { limit: 100, cursor },
          },
        }),
      );
      const { projects, metadata } = res.data;
      cursor = metadata?.cursors?.next || null;
      hasNext = (metadata?.hasNext as boolean) || false;
      filter = null;
      sort = null;
      projects && collectionProjects.push(...projects);
    } catch (error) {
      hasNext = false;
    }
  } while (hasNext);

  return collectionProjects;
};
export const getCollectionProjects = async (
  httpClient: IHttpClient,
  collectionId: string,
) => {
  return getAllCollectionProjectsWithInfo(httpClient, collectionId);
};

export const getFirstCollection = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: { $eq: false } },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections[0];
    });
};

export const getCollectionBySlug = async (
  flowAPI: PlatformControllerFlowAPI,
) => {
  const collectionSlug = getCollectionSlugFromUrl(flowAPI);
  return collectionSlug
    ? flowAPI.httpClient
        .request(
          queryCollections({
            query: {
              filter: { slug: collectionSlug },
              sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
            },
            includePageUrl: true,
          }),
        )
        .then((res) => {
          const {
            data: { collections = [] },
          } = res;

          return collections[0];
        })
    : {};
};

export const getFirstProject = async (httpClient: IHttpClient) => {
  return httpClient.request(queryProjects({ query: {} })).then((res) => {
    const {
      data: { projects = [] },
    } = res;
    return { project: projects[0] };
  });
};

export const getProjectBySlug = async (flowAPI: PlatformControllerFlowAPI) => {
  const projectSlug = getProjectSlugFromUrl(flowAPI);
  const collectionSlug = getCollectionSlugFromUrl(flowAPI);
  if (projectSlug && collectionSlug) {
    const res = await flowAPI.httpClient.request(
      getProjectPageData({ collectionSlug, projectSlug }),
    );
    return res?.data;
  } else {
    return {};
  }
};
export const getProjectIdBySlug = async (
  httpClient: IHttpClient,
  projectSlug: string,
) => {
  const res = await httpClient.request(
    queryProjects({ query: { filter: { slug: projectSlug } } }),
  );
  const projects = res.data.projects || [];
  return projects[0]?.id;
};
export const getCollectionIdBySlug = async (
  httpClient: IHttpClient,
  collectionSlug: string,
) => {
  const res = await httpClient.request(
    queryCollections({ query: { filter: { slug: collectionSlug } } }),
  );
  const collections = res.data.collections || [];
  return collections[0]?.id;
};

export const getCollectionsLength = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: false },
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections?.length;
    })
    .catch((e) => {
      console.error(e);
      return 0;
    });
};

export const getProjectItems = async (
  httpClient: IHttpClient,
  projectId: string,
  offset?: number,
): Promise<{ projectItems: Item[]; pagingMetadataV2: PagingMetadataV2 }> => {
  const { data } = await httpClient.request(
    listProjectItems({
      projectId,
      paging: { offset, limit: PROJECT_ITEM_PAGING_LIMIT },
    }),
  );
  const projectItems = data?.items || [];
  const pagingMetadataV2 = data?.pagingMetadataV2 || {};
  return { projectItems, pagingMetadataV2 };
};

export const getPortfolioSettings = async (httpClient: IHttpClient) => {
  const res = await httpClient.request(getPortfolioSettingsReq({}));
  return res;
};
