import {
  FlowEditorSDK,
  IHttpClient,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { openDashboardPanel } from './dashboardUtils';
import {
  getCollectionIdFromDynamicPage,
  getProjectIdFromDynamicPage,
} from './pagesUtils';

export const getRole = (wixCodeId: string) => {
  return wixCodeId.substring(1);
};

const addTabExec = (widgetDesignTabsBuilder, label: string, roles: any) => {
  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({
        label,
      })
      .groups()
      .set({
        roles: roles.map(getRole),
      });
  });
};

export const setWidgetDesignTabs = (
  widgetDesignTabsBuilder,
  widgetDesignTabsArray: widgetDesignTabsBuilderArray,
  t,
  isEditorX: boolean,
  isStudio: boolean,
) => {
  widgetDesignTabsArray.forEach(
    ({ label, roles, skipOnEditorX, skipOnStudio }) => {
      if (
        (isEditorX && skipOnEditorX && !isStudio) ||
        (isStudio && skipOnStudio)
      ) {
        return;
      }
      addTabExec(widgetDesignTabsBuilder, t(label), roles);
    },
  );
};

export const customizeNativeGalleryGFPPs = ({
  galleryRole,
  controllerBuilder,
  shouldHideSettingsGfpp,
  t,
}: {
  galleryRole: string;
  controllerBuilder: WidgetBuilder;
  shouldHideSettingsGfpp?: boolean;
  t: any;
}) => {
  controllerBuilder.configureConnectedComponents(
    galleryRole,
    (componentBuilder) => {
      componentBuilder.gfpp().set('mainAction1', {
        label: t('Native_Gallery_Gfpp_Main_Action_1_Layout'),
        actionType: 'layout',
        actionId: '',
      });

      componentBuilder.gfpp().set('connect', { behavior: 'HIDE' });
      if (shouldHideSettingsGfpp) {
        componentBuilder.gfpp().set('settings', { behavior: 'HIDE' });
        componentBuilder.gfpp('mobile').set('settings', { behavior: 'HIDE' });
      }
    },
  );
};

export const openDynamicProjectPageDashboard =
  ({
    editorSDK,
    httpClient,
    focusOn,
  }: {
    editorSDK: FlowEditorSDK;
    httpClient: IHttpClient;
    focusOn?: string;
  }) =>
  async (event: CustomEvent) => {
    const dynamicPageProjectId = await getProjectIdFromDynamicPage(
      editorSDK,
      httpClient,
    );
    openDashboardPanel(
      editorSDK,
      `portfolio/project/${dynamicPageProjectId}${
        focusOn ? `?pfocus=${focusOn}` : ''
      }`,
    );
  };

export const ProjectConfigureMainActionEditGfpp = ({
  controllerBuilder,
  editorSDK,
  httpClient,
  role,
  label,
  focusOn,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  httpClient: IHttpClient;
  role: string;
  label: string;
  focusOn?: string;
}) => {
  controllerBuilder.configureConnectedComponents(role, (componentBuilder) => {
    componentBuilder
      .gfpp()
      .set('mainAction2', {
        label,
        onClick: openDynamicProjectPageDashboard({
          editorSDK,
          httpClient,
          focusOn,
        }),
      })
      .set('connect', { behavior: 'HIDE' });
  });
};

const configureTextMainActionSettingsGfpp = ({
  controllerBuilder,
  editorSDK,
  t,
  role,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  t: any;
  role: string;
}) => {
  controllerBuilder.configureConnectedComponents(role, (componentBuilder) => {
    componentBuilder.gfpp().set('mainAction1', {
      label: t('Text_Component_Gfpp_Main_Action_1'),
      onClick: (event) => {
        editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef: event.detail.componentRef,
          // @ts-expect-error
          configuration: {
            controls: {
              alignment: {
                hidden: true,
              },
            },
          },
        });
      },
    });
  });
};

export const configureWidgetTextDesignGfpp = ({
  controllerBuilder,
  editorSDK,
  t,
  textComponentIds,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  t: any;
  textComponentIds: string[];
}) => {
  for (const textComponentId of textComponentIds) {
    configureTextMainActionSettingsGfpp({
      controllerBuilder,
      editorSDK,
      t,
      role: getRole(textComponentId),
    });
  }
};

export const openDynamicCollectionPageDashboard =
  ({
    editorSDK,
    httpClient,
    focusOn,
  }: {
    editorSDK: FlowEditorSDK;
    httpClient: IHttpClient;
    focusOn?: string;
  }) =>
  async (event?: CustomEvent) => {
    try {
      const dynamicPageCollectionId = await getCollectionIdFromDynamicPage(
        editorSDK,
        httpClient,
      );
      openDashboardPanel(
        editorSDK,
        `portfolio/collection/${dynamicPageCollectionId}${
          focusOn ? `?pfocus=${focusOn}` : ''
        }`,
      );
    } catch (e) {
      console.error(e);
      openDashboardPanel(editorSDK, `portfolio/collections`);
    }
  };

export const setWidgetDesignPanel = ({
  controllerBuilder,
  t,
  presetsTabArray,
  title,
  isEditorX,
  isStudio,
}: {
  controllerBuilder: WidgetBuilder;
  t: any;
  presetsTabArray: widgetDesignTabsBuilderArray;
  title: string;
  isEditorX: boolean;
  isStudio: boolean;
}) => {
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title,
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

    setWidgetDesignTabs(
      widgetDesignTabsBuilder,
      presetsTabArray,
      t,
      isEditorX,
      isStudio,
    );
  });
};

export const setWidgetPresetPanel = ({
  controllerBuilder,
  PresetsArray,
}: {
  controllerBuilder: WidgetBuilder;
  PresetsArray: WidgetPresetsArray;
}) => {
  controllerBuilder.configureWidgetPresets((widgetPresetsBuilder) => {
    widgetPresetsBuilder.setPresets(PresetsArray);
  });
};
