import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  getRole,
  openDynamicProjectPageDashboard,
  ProjectConfigureMainActionEditGfpp,
} from '../../../utils/manifestUtils';
import {
  ProjectHeaderWidgetComponentIds,
  GalleryCoverConfigurations,
} from './constants';
import { PROJECT_PAGE_AUTO_FOCUS_TARGETS } from '../../../constants';

export const setConfigureConnectedComponents = ({
  controllerBuilder,
  editorSDK,
  flowAPI,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
}) => {
  const { t } = flowAPI.translations;
  const { httpClient } = flowAPI;
  ProjectConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    httpClient,
    role: getRole(ProjectHeaderWidgetComponentIds.Title),
    label: t('Project_Page_Connected_Component_Title'),
  });
  ProjectConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    httpClient,
    role: getRole(ProjectHeaderWidgetComponentIds.Description),
    label: t('Project_Page_Connected_Component_Description'),
    focusOn: PROJECT_PAGE_AUTO_FOCUS_TARGETS.DESCRIPTION,
  });

  controllerBuilder.configureConnectedComponents(
    getRole(ProjectHeaderWidgetComponentIds.GalleyCover),
    (componentBuilder) => {
      componentBuilder.set({
        displayName: t('Project_Elements_Panel_Hero_Image'),
      });
      componentBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('Project_Page_Connected_Component_Cover'),
          onClick: openDynamicProjectPageDashboard({
            editorSDK,
            httpClient,
            focusOn: PROJECT_PAGE_AUTO_FOCUS_TARGETS.COVER,
          }),
        })
        .set('mainAction2', {
          behavior: 'HIDE',
        })
        .set('settings', {
          behavior: 'HIDE',
        })
        .set('layout', {
          behavior: 'HIDE',
        })
        .set('behaviors' as any, {
          behavior: 'HIDE',
        })
        .set('more' as any, {
          behavior: 'HIDE',
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('animation', {
          behavior: 'HIDE',
        });

      componentBuilder
        // @ts-expect-error
        .panel('design')
        // @ts-expect-error
        .configureControls(GalleryCoverConfigurations);
    },
  );
};
