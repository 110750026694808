import {
  ComponentRef,
  FlowEditorSDK,
  IHttpClient,
} from '@wix/yoshi-flow-editor';
import _ from 'lodash';
import {
  FeaturedCollectionWidgetData,
  PortfolioRoutersPrefix,
  classicDefaultInstallationSize,
} from '../editor-app/first-install/const/pagesConsts';
import {
  getCollectionIdBySlug,
  getProjectIdBySlug,
} from '../services/dataServices';

const PORTFOLIO_APP_DEF_ID = 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130';

export async function setPortfolioPagesStates(editorSDK: FlowEditorSDK) {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  const portfolioPages = portfolioPagesData.filter(
    ({ managingAppDefId }) => managingAppDefId === PORTFOLIO_APP_DEF_ID,
  );
  portfolioPages.forEach((pageData) => {
    editorSDK.document.pages.setState('', {
      state: {
        // @ts-expect-error
        [pageData.tpaPageId]: [{ id: pageData.id }],
      },
    });
  });
}

export const getApplicationRouter = async (
  editorSDK: FlowEditorSDK,
  prefix: string,
) => {
  const siteRouters = await editorSDK.routers.getAll('');
  const portfolioRouters = _.find(
    siteRouters,
    (router) => router.prefix === prefix,
  );
  return portfolioRouters;
};

export const updatePagesDataOnRouter = async (editorSDK: FlowEditorSDK) => {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  const portfolioRouters = await getApplicationRouter(
    editorSDK,
    PortfolioRoutersPrefix.PORTFOLIO_COLLECTIONS,
  );
  if (portfolioRouters) {
    const routerRef = await editorSDK.routers.getByPrefix('', {
      prefix: portfolioRouters?.prefix as string,
    });
    await editorSDK.routers.update('', {
      routerRef,
      config: { portfolioPagesData },
    });
  }
};

export const isProjectRouterAdded = async (editorSDK: FlowEditorSDK) => {
  const portfolioRouters = await getApplicationRouter(
    editorSDK,
    PortfolioRoutersPrefix.PORTFOLIO_PROJECT,
  );
  return !!portfolioRouters;
};

export const getProjectIdFromDynamicPage = async (
  editorSDK: FlowEditorSDK,
  httpClient: IHttpClient,
) => {
  try {
    const currentDynamic = await editorSDK.routers.getCurrentDynamicRouting('');
    const splittedRoute = currentDynamic.innerRoute.split('/');
    const projectSlug = splittedRoute[1];
    const currentProjectId = await getProjectIdBySlug(httpClient, projectSlug);
    return currentProjectId;
  } catch (e) {
    console.error(e);
  }
};

export const getCollectionIdFromDynamicPage = async (
  editorSDK: FlowEditorSDK,
  httpClient: IHttpClient,
) => {
  try {
    const currentDynamic = await editorSDK.routers.getCurrentDynamicRouting('');
    const splittedRoute = currentDynamic.innerRoute.split('/');
    const collectionSlug = splittedRoute[0];
    const currentCollectionId = await getCollectionIdBySlug(
      httpClient,
      collectionSlug,
    );
    return currentCollectionId;
  } catch (e) {
    console.error(e);
  }
};

export const addFeatureCollectionsToThePage = async (
  editorSDK: FlowEditorSDK,
  isResponsive: boolean,
) => {
  const { widgetId, presetId, mobilePresetId } = FeaturedCollectionWidgetData;
  const pageRef = (await editorSDK.pages.getCurrent('')) as ComponentRef;
  const [, sectionRef] = await editorSDK.document.components.getChildren('', {
    componentRef: pageRef as ComponentRef,
  });
  await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId,
    layout: {
      x: 0,
      y: 0,
      fixedPosition: false,
      width: classicDefaultInstallationSize.width,
      height: classicDefaultInstallationSize.height,
      scale: 1,
      rotationInDegrees: 0,
    } as any,
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 400,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'px',
          value: 700,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'start',
        margins: {},
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'start',
        type: 'GridItemLayout',
      },
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
    },
    scopedPresets: {
      desktop: { layout: presetId, style: presetId },
      mobile: { layout: mobilePresetId, style: mobilePresetId },
    },
    installationType: 'closed',
    containerRef: sectionRef,
  });
};
